<template>
  <UiButton
    :label="label"
    :right-icon="isOpen ? 'chevron-up' : 'chevron-down'"
    theme="muted"
    variant="outline"
    size="md"
    @click="() => (isOpen = !isOpen)"
  />
</template>

<script setup lang="ts">
/**
 * A simple toggle button that changes its label and icon when clicked.
 * Control by passing a boolean `v-model` prop.
 * Additional classes can be passed from the parent to style the button.
 */

export type UiToggleButtonProps = {
  labelOpen?: string
  labelClose?: string
}
const props = withDefaults(defineProps<UiToggleButtonProps>(), {
  labelOpen: 'Read More',
  labelClose: 'Read Less',
})

const isOpen = defineModel({ type: Boolean, default: false })

const label = computed(() => {
  return isOpen.value ? props.labelClose : props.labelOpen
})
</script>
